import { render, staticRenderFns } from "./PontChaban.vue?vue&type=template&id=412a6d64&scoped=true&"
import script from "./PontChaban.vue?vue&type=script&lang=js&"
export * from "./PontChaban.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "412a6d64",
  null
  
)

export default component.exports